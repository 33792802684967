import React, { useState, useRef } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ReactCardFlip from 'react-card-flip';

import rightButton from '../img/icons/right.webp'; // Importando a imagem do botão direito
import leftButton from '../img/icons/left.webp'; // Importando a imagem do botão esquerdo

function EquipaPage({ Equipa }) {
  const [isFlipped, setIsFlipped] = useState(Array(Equipa.length).fill(false));
  const [autoPlay, setAutoPlay] = useState(true);

  const carouselRef = useRef(null);

  const handleClick = (index) => {
    setIsFlipped((prev) => {
      const newFlips = [...prev];
      newFlips[index] = !newFlips[index];
      return newFlips;
    });
    setAutoPlay(false);
  };

  const handleBeforeChange = () => {
    setIsFlipped(Array(Equipa.length).fill(false));
    setAutoPlay(true);
  };

  const responsive = {
    superLargeDesktop: {
      // For very large screens
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  const customTransition = 'transform 1000ms ease-in-out';

  return (
    <div className="team-page" style={{ padding: '20px' }}>
      <style >{`
        /* Team Page Styles */
        .team-page {
          padding: 20px;
        }

        /* Team Member Card Styles */
        .team-member-card {
          cursor: pointer;
          border: 1px solid #ccc;
          border-radius: 8px;
          padding: 20px;
          margin: 20px auto;
          background-color: #55917F;
          color: white;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 500px; /* Increased card height */
          width: 350px; /* Increased card width */
          margin: 10px auto;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: transform 0.8s, box-shadow 0.3s;
          position: relative;
          backface-visibility: hidden; /* Hide the backface when not flipped */
          transform-style: preserve-3d;
        }

         @media (max-width: 768px) { 
          .team-member-card {
            width: auto; /* Adjust width on smaller screens for responsiveness */
            margin: 10px auto; /* Reduce margin for less vertical space */
          }
        }
          .team-member-card.back .team-member-info {
          display: flex; /* Use flexbox for better content alignment */
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%; /* Ensure content fills the entire card height */
        }

          .team-member-card.front,
        .team-member-card.back {
          
          box-sizing: border-box; /* Include padding and border in the element's total width and height */
        }

        .team-member-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }

        .team-member-image {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: 20px;
          border: 4px solid white;
        }

        .team-member-info {
          flex-grow: 1;
        }

        .team-member-info h3 {
          margin-bottom: 5px;
        }

        .role {
          font-style: italic;
          color: lightgray;
          margin-bottom: 20px;
        }

        .learn-more {
          background-color: transparent;
          border: 2px solid white;
          color: white;
          padding: 8px 16px;
          border-radius: 20px;
          cursor: pointer;
          transition: background-color 0.3s, color 0.3s;
        }

        .learn-more:hover {
          background-color: white;
          color: #55917F;
        }

        .team-member-card.back {
          padding: 40px;
          
        }

        
      `}</style>
      
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        autoPlay={autoPlay}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        transitionDuration={1000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={['tablet', 'mobile']}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        customTransition={customTransition}
        ref={carouselRef}
        beforeChange={handleBeforeChange}
        customLeftArrow={<img src={leftButton} alt="Left" />} // Botão esquerdo
        customRightArrow={<img src={rightButton} alt="Right" />} // Botão direito
      >
        {Equipa.map((membro, index) => (
          <ReactCardFlip isFlipped={isFlipped[index]} flipDirection="horizontal" key={membro.id}>
          <div className="team-member-card front" onClick={() => handleClick(index)}>
            <img src={membro.img} alt={membro.nome} className="team-member-image" />
            <div className="team-member-info">
              <h3>{membro.nome}</h3>
              <p className="role">{membro.subcargo}</p>
              <p >{membro.funcao}</p>
              
              <button className="learn-more">Saiba mais</button> 
            </div>
          </div>
          <div className="team-member-card back" onClick={() => handleClick(index)}>
            <div className="team-member-info">
              <h3>{membro.nome}</h3>
              <p className='role'>{membro.funcao}</p>
              
              <p>{membro.textoAlternativo}</p>
            </div>
          </div>
        </ReactCardFlip>
        ))}
      </Carousel>
    </div>
  );
}

export default EquipaPage;