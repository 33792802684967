import React from 'react';
import Maps from './Maps';

function Footer() {
    return (
        <div className='footer-container'>
            <style>{`
                .footer-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: #4b7164;
                    color: white;
                    padding: 20px;
                    text-align: center;
                    border-radius: 8px;
                }

                .footer-container h2 {
                    font-size: 24px;
                    margin-bottom: 20px;
                }

                .footer-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                    width: 100%;
                    max-width: 800px;
                }

                #map {
                    width: 400px;
                    height: 300px;
                    border-radius: 8px;
                    margin-right: 20px;
                }

                .footer-details {
                    font-size: 16px;
                }

                .footer-details p {
                    margin: 5px 0;
                }

                .footer-details .address {
                    font-weight: bold;
                    margin-top: 10px;
                }

                @media (max-width: 768px) {
                    .footer-content {
                        flex-direction: column;
                        align-items: center;
                    }

                    #map {
                        margin: 0 0 20px 0;
                        width: 100%;
                        max-width: 300px;
                    }

                    .footer-details {
                        text-align: center;
                    }
                }

                .footer-rights {
                    margin-top: 20px;
                    font-size: 14px;
                    color: #ddd;
                    border-top: 1px solid #ddd;
                    padding-top: 10px;
                    width: 100%;
                    max-width: 800px;
                }

                .footer-rights p {
                    margin: 0;
                }
            `}</style>

            <h2 style={{ fontFamily: 'Montserrat' }}>Como nos encontrar</h2>
            <div className='footer-content'>
                <Maps />
                <div className='footer-details'>
                    <p style={{ fontFamily: 'Montserrat' }}>Contactos</p>
                    <p>Telefone: <a href="tel:+351 912 977 981" style={{ textDecoration: 'none', color: 'white' }}>912 977 981</a></p>
                    <p>Email: <a href="mailto:geral@coresense.pt" style={{ textDecoration: 'none', color: 'white' }}>geral@coresense.pt</a></p>
                    <a href='https://www.instagram.com/coresense_/'>
                        <img src='instagram-icon.png' style={{ width: '30px', height: '30px' }} alt='Instagram Coresense' />
                    </a>
                    <p className='address'>Morada:</p>
                    <p>Rua de São Romão 69<br />2790-436 Queijas</p>
                </div>
            </div>
            <div className='footer-rights'>
                <p style={{ fontFamily: 'Montserrat' }}>Todos os direitos reservados à Coresense - Feito com ♡ pelo Duarte</p>
            </div>
        </div>
    );
}

export default Footer;
