import React from 'react';
import logo from '../download.svg';
import '../App.css';
import headervideo from '../header.MOV';
import TestemunhosCarousel from '../components/slider';
import Servicos from '../components/servicos';
import Modalidade from '../components/modalidades';
import EquipaPage from '../components/equipa';
import ResponsiveTable from '../components/tablevalues';
import ContactForm from '../components/contactform';
import Footer from '../components/footer';
import coach from '../img/modalidades/coaching.jpg';
import osteopatia from '../img/modalidades/osteopatia.jpg';
import pilates from '../img/modalidades/pilates.jpg';
import fisioterapia from '../img/modalidades/fisioterapia.jpg';
import nutricao from '../img/modalidades/nutrição.jpg';
import massagem from '../img/modalidades/massagens.jpg';
import fullbody from '../img/modalidades/fullbody.jpg';
import treino from '../img/modalidades/treinoperso.jpg';


function Home() {

    const servicos = [
        {
            id: 1,
            title: 'Osteopatia',
            text: `É uma abordagem terapêutica que
      procura a origem das disfunções físicas,
      reconhecendo a interconexão entre os
      sistemas do corpo. Com técnicas manuais
      precisas, trabalha para restaurar o
      equilíbrio, promovendo a cura ao tratar
      não apenas os sintomas, mas as causas
      subjacentes.`,
            img: `${osteopatia}`
        },
        {
            id: 2,
            title: 'Fisioterapia',
            text: `Utiliza métodos e técnicas manuais para
      prevenir, tratar e reabilitar condições
      musculoesqueléticas e neuromotoras. Foca
      a sua atenção em melhorar a função e a
      mobilidade e otimizar a funcionalidade e
      qualidade de vida dos pacientes.`,
            img: `${fisioterapia}`
        },
        {
            id: 3,
            title: 'Nutrição',
            text: `Na Coresense,entendemos que a Nutrição é essencial para o equilíbrio,
      com uma abordagem holística que considera não apenas as necessidades 
      nutricionais, mas também o impacto emocional e psicológico dos
       alimentos. Promovemos escolhas conscientes e sustentáveis para o bem-estar.`,
            img: `${nutricao}`
        },
        {
            id: 4,
            title: 'Coaching',
            text: `Ajudamos na jornada de autodescoberta
    e crescimento pessoal, guiando os pacientes na definição de metas e a
    desenvolverem estratégias para
    superarem os desafios. A nossa
    abordagem foca na potencialização das
    habilidades individuais, promovendo
    autoconsciência e empowerment.`,
            img: `${coach}`
        },
        {
            id: 5,
            title: 'Massagens',
            text: `As nossas massagens oferecem um refúgio
    de relaxamento e rejuvenescimento.
    As sessões são
    orientadas para aliviar o stress, reduzir a
    tensão muscular e promover um equilíbrio
    holístico. Cuide-se e permita-se a
    embarcar numa jornada revigorante
    para o corpo e a mente.`,
            img: `${massagem}`
        }
    ];

    const Modalidades = [
        {
            id: 1,
            title: 'Pilates',
            text: `É uma prática que fortalece o corpo de forma holística,
       fortalecenuo a conexão mente-corpo.
        As aulas, orientadas por instrutoras qualificadas, 
        focam-se em exercícios controlados para melhorar força,
         flexibilidade, equilíbrio e postura, enquanto promovem a consciência
          corporal. Com foco na respiração e no alinhamento, o Pilates
           beneficia pessoas de todas as idades e níveis de condição física,
            melhorando a saúde e o bem-estar geral`,
            img: `${pilates}`
        },
        {
            id: 2,
            title: 'Full-Body',
            text: `É uma modalidade que abrange todos os grupos musculares,
       proporcionando resultados eficazes num curto período de tempo.
        São aulas onde pode fortalecer, tonificar e melhorar a sua resistência
         física. Experimente o Full-Body na Coresense e eleve o seu treino
          para o próximo nivel.`,
            img: `${fullbody}`
        },
        {
            id: 3,
            title: 'Treino Personalizado',
            text: `O Treino Personalizado é uma experiênciaexclusiva,
       adaptada às necessidades e objetivos individuais de cada cliente.
        A nossa abordagem cuidadosa, guiada por profissionais especializados, 
        visa otimizar resultados, promover a saúde e criar hábitos sustentáveis. 
        Seja para alcançar metas específicas de fitness ou melhorar o bem-estar
         geral, o nosso treino personalizado é a chave para uma jornada de 
         exercícios eficaz e personalizada. `,
            img: `${treino}`
        },

    ];


    const Testemunhos = [
        {
            id: 1,
            text: `Conheci este grupo maravilha ainda a pandemia não tinha começado. Grupo atento, empenhado, disponível, solidário, interessado pelo outro e, a juntar a estes adjetivos, muito profissional.\n
        Num só espaço, todas as áreas ( físicas e mentais) se completam, cumprindo um importante princípio dos gregos "mente sã em corpo são". Num momento menos bom da minha vida contribuíram ( e continuam a contribuir) para que com os exercícios de pilates e respetiva postura e respiração, bem como com uma junção correta de alimentos, a dor física e mental fossem sendo superadas, mas sobretudo a consciência da sua existência.\n
        E esta consciência leva à sua superação.\n
        Continuem esta Vossa caminhada.\n
        \n Bem haja.
        `,
            nome: 'Isabel Pinto',
        },
        {
            id: 2,
            text: `"Conheci a Filipa na Coresense quando
       procurava uma solução para acabar com as minhas 
       dores nas costas. \n A sua abordagem cuidadosa e profissional fez toda a diferença.
        A Filipa não só aliviou as minhas dores, como também conseguiu identificar
         a causa subjacente. Estou eternamente grata pelo alívio que encontrei
          com o cuidado especializado da Filipa."
      `,
            nome: 'Ana Silva',
        },
        {
            id: 3,
            text: `"A Maria mudou minha vida! \n 
    A sua orientação e apoio para aprender a ter uma alimentação mais saudável foram inestimáveis.
     Ela não só me ajudou a desenvolver uma alimentação equilibrada, mas também me ensinou a cultivar uma relação mais saudável com a comida.
      Estou mais saudável, mais feliz e mais confiante graças à sua abordagem ."
    `,
            nome: 'Joana Lemos',
        },

        {
            id: 4,
            text: `"Quando decidi procurar orientação para uma mudança de carreira, encontrei na Coach Rita da Coresense não apenas uma orientação, 
  mas uma aliada essencial nesta minha jornada de transformação. \n A dedicação e calma da Rita foram fundamentais para me ajudar a navegar 
  por este processo desafiador.
   Com a sua orientação perspicaz, consegui não só identificar novas oportunidades que me surgiram, mas também ganhar clareza
    sobre os meus próprios talentos e paixões. Estou extremamente grato pela sua orientação e recomendo os seus serviços a qualquer
     pessoa que esteja à procura de uma mudança significativa na sua vida profissional."
  `,
            nome: 'Jorge Sena',
        },
    ];

    const Equipa = [
        { id: 1, nome: 'Filipa Severino', img: 'filipa.png', funcao: 'Osteopata e Fisioterapeuta', subcargo:'Co-Founder', textoAlternativo: 'Com uma abordagem meticulosa o seu objetivo é ir além dos sintomas. Tem como missão desvendar a origem do desconforto, utilizando os seus conhecimentos especializados para o ajudar a equilíbrar do seu corpo.' },
        { id: 2, nome: 'Maria Severino', img: 'maria.jpg', funcao: 'Nutricionista', subcargo:'Co-Founder', textoAlternativo: 'Dedicada à simbiose da Nutrição e do Treino. Irá ajudá-lo a ter uma alimentação mais consciente e sustentável, desenvolvendo um protocolo de treino ajustado às suas necessidades.' },
        { id: 3, nome: 'Rita Ventura', img: 'rita.jpg', funcao: 'Coach',subcargo:'Co-Founder', textoAlternativo: 'Guiada por inspirar a transformação pessoal. Com uma abordagem centrada no indivíduo, dedica-se a orientar o percurso de crescimento pessoal ajudando a criar metas alcançáveis com uma mentalidade de aceitação e mudança.' },
        { id: 4, nome: 'Carolina Pereira', img: 'carolina.jpg', funcao: 'Osteopata e Fisioterapeuta',subcargo:'', textoAlternativo: 'Trabalha com uma abordagem centrada no paciente, comprometendo-se em oferecer cuidados especializados que procuram a origem do desconforto e da dor.' },
    ];

    const nossaIdentidadeRef = React.useRef(null);
    const contactosRef = React.useRef(null);
    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <div className="App">
            <div className='header'>
                
                   
                <video className="headervideo" autoPlay loop muted playsInline style={{ position: 'relative', zIndex: '1' }}>
                    <source src={headervideo} type="video/mp4" />
                </video>
                <div className='overlay'>
                    <table>
                        <tr>
                            <img src={logo} className="App-logo" alt="logo" />
                        </tr>
                        <tr className='buttons'>
                            <button className='button' onClick={() => scrollToSection(nossaIdentidadeRef)}>Sobre Nós</button>
                            <button className='button'><a href='https://pt.zappysoftware.com/m/coresense' target='_blank' style={{color:'white', textDecoration:'none'}}>Marcações</a></button>
                            <button className='button' onClick={() => scrollToSection(contactosRef)}>Contactos</button>
                        </tr>
                    </table>
                </div>
            </div>
            <div className='content' ref={nossaIdentidadeRef}>
                <p className='title'><medium>A nossa identidade</medium></p>
                <p className='text'>A Coresense explora uma nova abordagem na saúde e bem-estar.<br />
                    Contamos com uma equipa multidisciplinar e altamente capacitada que através da sua empatia oferece um serviço personalizado.<br />
                    Reconhecemos e celebramos a singularidade de cada cliente oferecendo planos de cuidados
                    individualizados que tratam não apenas os sintomas mas promovem mudanças de estilo de vida duradouras capacitando
                    cada pessoa a alcançar o seu máximo potencial de saúde e felicidade.
                </p>
                <br />
                <p className='title'><medium>Áreas de atuação</medium></p>
                <Servicos servicos={servicos} />
                <br />
                <button className='button'><a href='https://pt.zappysoftware.com/m/coresense' target='_blank' style={{color:'white', textDecoration:'none'}}>Marcações</a></button>
                <br />
                <p className='title'><medium>Modalidades Desportivas:</medium></p>
                <Modalidade modalidades={Modalidades} />
                <br />
                <button className='button' onClick={() => scrollToSection(contactosRef)}>Marque a sua aula experimental</button>
                <br />
                <p className='title'><medium>A nossa missão:</medium></p>
                <p className='text'>A nossa missão é oferecer um espaço onde encontrará apoio,
                    orientação e uma equipa dedicada, que trabalha em harmonia para ajudá-lo
                    a alcançar uma vida plena e saudável em todos os aspectos: corpo,
                    mente e espírito. <br />Comprometemo-nos a capacitá-lo a viver de forma
                    holística, proporcionando cuidados individualizados e promovendo mudanças
                    de estilo de vida que impulsionam o seu bem-estar a longo prazo.</p>
                <br />
                <p className='title'><medium>Testemunhos</medium></p>
                <TestemunhosCarousel testemunhos={Testemunhos} />
                <br />
                <p className='title'><medium>Conheça a nossa equipa</medium></p>
                <br />
                <EquipaPage Equipa={Equipa} />
                <br />
                <p className='title'><medium>Os nossos valores</medium></p>
                <ResponsiveTable />
                <br ref={contactosRef} />
                <ContactForm />
                <Footer />


            </div>
        </div>
    );
}

export default Home;
