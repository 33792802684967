import React, { useState, useRef, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styled from 'styled-components';

const Card = styled.div`
background-color: rgba(158, 143, 178, 0.79);
border-radius: 24px;
border: 1px solid #979797;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
margin: 20px;
display: flex;
flex-direction: column;
overflow: hidden;
`;

const CardImage = styled.img`
width: 100%;
height: 20rem;
object-fit: cover;
border-top-left-radius: 24px;
border-top-right-radius: 24px;
`;

const CardContent = styled.div`
padding: 20px;
flex: 1;
`;

const CardTitle = styled.p`
color: white;
font-size: 1.8rem;
margin: 10px 0;
font-family: Montserrat, sans-serif;
font-weight: bold;
`;

const CardText = styled.p`
color: white;
font-size: 1rem;
line-height: 1.2rem;
max-height: ${({ expanded }) => (expanded ? 'none' : '6rem')}; /* Aproximadamente 5 linhas */
overflow: hidden;
position: relative;
`;

const ReadMoreButton = styled.button`
background: none;
color: white;
border: none;
cursor: pointer;
padding: 0;
font-size: 1rem;
text-decoration: underline;
margin-top: 10px;
`;

function Modalidade({ modalidades }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };



  
  const [expandMap, setExpandMap] = useState({});

  useEffect(() => {
    // Carregar o estado do localStorage na montagem inicial
    const savedExpandMap = JSON.parse(localStorage.getItem('expandMap')) || {};
    setExpandMap(prevMap => ({ ...savedExpandMap, ...prevMap })); 
  }, []); // Executa apenas uma vez na montagem

  useEffect(() => {
    // Salvar no localStorage sempre que expandMap mudar
    localStorage.setItem('expandMap', JSON.stringify(expandMap));
  }, [expandMap]); 

  


  const textRefs = useRef([]);

  useEffect(() => {
    // Garantir que textRefs tenha o mesmo comprimento que modalidades.length
    textRefs.current = Array(modalidades.length).fill().map((_, i) => textRefs.current[i] || null);

    // Salvar expandMap no localStorage sempre que ele for atualizado
    localStorage.setItem('expandMap', JSON.stringify(expandMap));
  }, [modalidades, expandMap]);

  const onExpansion = (id) => {
    setExpandMap(prevMap => ({
      ...prevMap,
      [id]: !prevMap[id],
    }));
  };

  const hasMoreThanFive = (index) => {
    const textElement = textRefs.current[index];
    if (!textElement) return false;

    // Calcular a altura real do conteúdo do texto
    const actualHeight = textElement.scrollHeight;

    // Comparar com uma altura razoável (por exemplo, maxHeight) para decidir
    const lineHeight = parseInt(getComputedStyle(textElement).lineHeight);
    const maxHeight = 5 * lineHeight; // Altura para aproximadamente 5 linhas de texto
    return actualHeight > maxHeight;
  };



  return (
    <Carousel responsive={responsive} infinite={true} containerClass="my-carousel-container">
      {modalidades.map((modalidade, index) => {
        const isExpanded = expandMap[modalidade.id] || false;

        return (
          <Card key={modalidade.id}>
            <CardImage src={modalidade.img} alt="service" />
            <CardContent>
              <CardTitle>{modalidade.title}</CardTitle>
              <CardText expanded={isExpanded} ref={(el) => (textRefs.current[index] = el)}>
                {modalidade.text}
              </CardText>
              {hasMoreThanFive(index) && (
                <ReadMoreButton onClick={() => onExpansion(modalidade.id)}>
                  {isExpanded ? 'Ler menos' : 'Ler mais'}
                </ReadMoreButton>
              )}
            </CardContent>
          </Card>
        );
      })}
    </Carousel>
  );
}

export default Modalidade;
