import React, { useState, useRef } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const CarouselContainer = styled.div`
  /* Adicione estilos conforme necessário */
`;

const TestemunhoCard = styled.div`
  border-radius: 8px;
  padding: 20px;
  transition: max-height 0.5s ease;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const QuoteIcon = styled(FontAwesomeIcon)`
  font-size: 1.8rem;
  color: #55917F;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const TestemunhoTexto = styled.blockquote`
  font-size: 1.15rem;
  margin-bottom: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: ${(props) => (props.expanded ? 'none' : '5.5em')};
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.expanded ? 'none' : 5)};
  -webkit-box-orient: vertical;
  transition: max-height 0.5s ease;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const TestemunhoNome = styled.p`
  margin-top: 15px;
`;

const ReadMoreButton = styled.button`
    background-color: #FF5D73;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px;
    
    width: fit-content;
    height: auto;
    font-size: 90%;

    @media screen and (max-width: 422px) {
      margin-left: 0;
      margin-bottom: 5px;
      
    }
`;

const TestemunhosCarousel = ({ testemunhos }) => {
  const [expanded, setExpanded] = useState({});
  const carouselRef = useRef(null);

  const toggleExpand = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleBeforeChange = () => {
    setExpanded({});
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  return (
    <CarouselContainer>
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        infinite
        autoPlay
        autoPlaySpeed={5000}
        transitionDuration={500}
        pauseOnHover
        draggable
        swipeable
        containerClass="my-carousel-container"
        arrows={false}
        customTransition="all 0.5s"
        keyBoardControl
        beforeChange={handleBeforeChange}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        showDots={false}
      >
        {testemunhos.map((testemunho) => (
          <TestemunhoCard key={testemunho.id}>
            <QuoteIcon icon={faQuoteLeft} />
            <TestemunhoTexto expanded={expanded[testemunho.id]}>
              {testemunho.text}
            </TestemunhoTexto>
            <TestemunhoNome>- {testemunho.nome}</TestemunhoNome>
            
            {testemunho.text.split('\n').length > 5 && (
              <ReadMoreButton onClick={() => toggleExpand(testemunho.id)}>
                {expanded[testemunho.id] ? 'Ler Menos' : 'Ler Mais'}
              </ReadMoreButton>
            )}
          </TestemunhoCard>
        ))}
      </Carousel>
    </CarouselContainer>
  );
};

export default TestemunhosCarousel;
