import React from 'react';
import integridade from '../img/valores/integridade.jpeg';
import compromisso from '../img/valores/compromisso.jpeg';
import inovacao from '../img/valores/inovar.jpeg';
import equipa from '../img/valores/equipa.jpeg';


function ResponsiveTable() {
    return (
        <div className='table-container'>
            <style>{`
                /* Basic Table Styling */
                .table-container {
                    width: 100%;
                    overflow-x: auto; 
                     
                    box-sizing: border-box; 
                }

                table {
                    width: 100%;
                    border:none;
                    table-layout: fixed; 
                }

                td {
                    
                    vertical-align: middle; 
                    
                    text-align: left; /* Align text to the left */
                    word-break: break-word; /* Allow long words to break into multiple lines */
                }

                /* Typography */
                .title {
                    font-size: 1.2em;  
                    font-weight: bold;
                    margin-bottom: 5px; 
                }

                .text {
                    font-size: 1em; 
                    line-height: 1.5; 
                }

                /* Image Styling */
                .img-col {
                width: 50%;         /* Cada coluna ocupa 50% da largura */
                height: 500px;       /* Altura fixa para garantir formato retangular */
                overflow: hidden;    /* Garante que a imagem não ultrapasse o container */
                object-fit: cover;   /* Faz a imagem cobrir todo o espaço, mantendo proporção */
                }

                .imgtable {
                width: 100%;         /* Imagem ocupa toda a largura da coluna */
                height: 100%;       /* Imagem ocupa toda a altura da coluna */
                object-fit: cover;  /* Faz a imagem cobrir todo o espaço, mantendo proporção */
                }

                /* Responsive Adjustments */
                @media (max-width: 768px) {
                    

                   
                    
                    .img-col {
                        width: 100%; 
                        margin-bottom: 10px; 
                    }

                    .content-cell {
                        display: flex; 
                        flex-direction: column; 
                        align-items: center;
                        height: auto;
                        width: 100%;
                    }

                    .text-col { /* Ensure text doesn't overlap on smaller screens */
                        width: 100%;
                        box-sizing: border-box;
                    }
                }
            `}</style>
            <table>
                <tbody>
                    <tr className='content-cell'>
                        <td className='img-col'>
                            <img src={compromisso} className='imgtable' alt='Compromisso com o cliente'/>
                        </td>
                        <td className='text-col text-content'>
                            <p className='title'>Compromisso com o Cliente</p>
                            <p className='text'>Colocamos os nossos clientes no centro de tudo o que fazemos, oferecendo serviços personalizados e de qualidade.</p>
                        </td>
                    </tr>
                    <tr className='content-cell'>
                        <td className='text-col text-content'>
                            <p className='title'>Integridade</p>
                            <p className='text'>Pautamos as nossas práticas pela transparência, honestidade e ética, assegurando um ambiente de confiança e respeito.</p>
                        </td>
                        <td className='img-col'>
                            <img src={integridade} className='imgtable' alt='Integridade' />
                        </td>
                    </tr>
                    <tr className='content-cell'>
                        <td className='img-col'>
                            <img src={inovacao} className='imgtable' alt='Inovação' />
                        </td>
                        <td className='text-col text-content'>
                            <p className='title'>Inovação</p>
                            <p className='text'>Procuramos constantemente novas abordagens e conhecimentos para melhorar os nossos serviços e proporcionar resultados eficazes.</p>
                        </td>
                    </tr>
                    <tr className='content-cell'>
                        <td className='text-col text-content'>
                            <p className='title'>Trabalho em Equipa</p>
                            <p className='text'>Valorizamos a colaboração e a comunicação eficaz entre a nossa equipa multidisciplinar, garantindo uma abordagem abrangente aos cuidados de saúde.</p>
                        </td>
                        <td className='img-col'>
                            <img src={equipa} className='imgtable' alt='Trabalho em equipa' />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default ResponsiveTable;
