import React, { useState, useRef, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styled from 'styled-components';

const ServiceCard = styled.div`
background-color: rgba(85, 145, 127, 0.78);
border-radius: 24px;
border: 1px solid #979797;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
margin: 20px;
display: flex;
flex-direction: column;
overflow: hidden;
`;

const ServiceImage = styled.img`
width: 100%;
height: 20rem;
object-fit: cover;
border-top-left-radius: 24px;
border-top-right-radius: 24px;
`;

const ServiceContent = styled.div`
padding: 20px;
flex: 1;
`;

const ServiceTitle = styled.p`
color: white;
font-size: 1.8rem;
margin: 10px 0;
font-family: Montserrat, sans-serif;
font-weight: bold;
`;

const ServiceText = styled.p`
color: white;
font-size: 1rem;
line-height: 1.2rem;
max-height: ${({ expanded }) => (expanded ? 'none' : '7rem')}; // Approximately 5 lines
overflow: hidden;
position: relative;
`;

const ReadMoreButton = styled.button`
background: none;
color: white;
border: none;
cursor: pointer;
padding: 0;
font-size: 1rem;
text-decoration: underline;
margin-top: 10px;
`;


function Servicos({ servicos }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [expandMap, setExpandMap] = useState({});
  const textRefs = useRef([]);

  useEffect(() => {
    // Ensure textRefs array matches the length of servicos
    textRefs.current = textRefs.current.slice(0, servicos.length);
  }, [servicos]);

  useEffect(() => {
    // Load state from localStorage on initial mount
    const savedExpandMap = JSON.parse(localStorage.getItem('expandMap')) || {};
    setExpandMap(prevMap => ({ ...savedExpandMap, ...prevMap }));
  }, []);

  useEffect(() => {
    // Save to localStorage whenever expandMap changes
    localStorage.setItem('expandMap', JSON.stringify(expandMap));
  }, [expandMap]);

  const toggleExpand = (id) => {
    setExpandMap((prevMap) => ({
      ...prevMap,
      [id]: !prevMap[id],
    }));
  };

  const hasMoreThanFiveLines = (index) => {
    const textElement = textRefs.current[index];
    if (!textElement) return false;

    const lineHeight = parseInt(getComputedStyle(textElement).lineHeight);
    const maxHeight = lineHeight * 7; // 5 lines
    return textElement.scrollHeight > maxHeight;
  };


  return (
    <Carousel responsive={responsive} infinite containerClass="my-carousel-container">
      {servicos.map((servico, index) => {
        const isExpanded = expandMap[servico.id] || false;

        return (
          <ServiceCard key={servico.id}>
            <ServiceImage src={servico.img} alt="service" />
            <ServiceContent>
              <ServiceTitle>{servico.title}</ServiceTitle>
              <ServiceText
                expanded={isExpanded}
                ref={(el) => (textRefs.current[index] = el)}
              >
                {servico.text}
              </ServiceText>
              {hasMoreThanFiveLines(index) && (
                <ReadMoreButton onClick={() => toggleExpand(servico.id)}>
                  {isExpanded ? 'Ler menos' : 'Ler mais'}
                </ReadMoreButton>
              )}
            </ServiceContent>
          </ServiceCard>
        );
      })}
    </Carousel>
  );
}

export default Servicos;
