import React, { useState, useRef } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [captchaToken, setCaptchaToken] = useState('');

    const recaptchaRef = useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        if (!captchaToken) {
            setError('Por favor, verifique o reCAPTCHA.');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post('/api/sendEmail', { name, email, message, captchaToken });
            setLoading(false);
            if (response.status === 200) {
                setSuccess('Email enviado com sucesso!');
                setName('');
                setEmail('');
                setMessage('');
                setCaptchaToken(''); // Limpa o captchaToken primeiro
                recaptchaRef.current.reset(); // Reseta o reCAPTCHA após limpar o token
            } else {
                setError('Ocorreu um erro ao enviar o email.');
            }
        } catch (err) {
            setLoading(false);
            setError('Ocorreu um erro ao enviar o email.');
        }
    };

    const handleRecaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    return (
        <div>
            <style>{`
                .form-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 20px;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    font-family: 'Montserrat';
                }

                .title {
                    font-size: 24px;
                    margin-bottom: 10px;
                    font-family: 'Montserrat';
                }

                .text {
                    font-size: 16px;
                    margin-bottom: 20px;
                    text-align: center;
                    font-family: 'Montserrat';
                }

                .inputbox {
                    width: 300px;
                    padding: 10px;
                    margin: 10px 0;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    font-size: 14px;
                    font-family: 'Montserrat';
                }

                .inputbox:focus {
                    border-color: #ff6347;
                    outline: none;
                    box-shadow: 0 0 5px rgba(255, 99, 71, 0.5);
                }

                input[type="email"]:invalid {
                    border-color: #ff6347;
                }

                .sendbutton {
                    width: 150px;
                    padding: 10px;
                    background-color: #FF5D73;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    font-size: 16px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }

                .sendbutton:hover {
                    background-color: #e5533d;
                }

                @media (max-width: 400px) {
                    .inputbox {
                        width: 100%;
                    }
                    .sendbutton {
                        width: 100%;
                    }
                }

                .message {
                    margin-top: 20px;
                    font-size: 16px;
                    text-align: center;
                }

                .success {
                    color: green;
                }

                .error {
                    color: red;
                }
            `}</style>
            <h1 className='title'>Deseja ser contactado</h1>
            <p className='text'>Preencha o formulário e mais tarde entraremos em contato</p>

            <form className='form-container' onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Nome'
                    className='inputbox'
                    required
                />
                <br />
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Email'
                    className='inputbox'
                    required
                />
                <br />
                <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder='Mensagem'
                    className='inputbox'
                    required
                />
                <br />
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LcSgQoqAAAAAPT0QWbr5R0BSF4UzB-Y5t6L51kN"
                    onChange={handleRecaptchaChange}
                />
                <br />
                <button type="submit" className='sendbutton' disabled={loading}>
                    {loading ? 'Enviando...' : 'Enviar'}
                </button>
            </form>
            {error && <p className="message error">{error}</p>}
            {success && <p className="message success">{success}</p>}
        </div>
    );
};

export default ContactForm;
