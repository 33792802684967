import React, { useEffect } from 'react';

function Maps() {
  useEffect(() => {
    const loadScript = (url, callback) => {
      const existingScript = document.querySelector(`script[src="${url}"]`);
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = url;
        script.defer = true;
        script.async = true;
        script.onload = callback;
        document.head.appendChild(script);
      } else {
        existingScript.onload = callback;
      }
    };

    const initMap = () => {
      const mapElement = document.getElementById('map');
      if (!mapElement) return;

      if (window.google && window.google.maps) {
        const map = new window.google.maps.Map(mapElement, {
          center: { lat: 38.724140, lng: -9.262469 },
          zoom: 18,
        });

        const marker = new window.google.maps.Marker({
          position: { lat: 38.724140, lng: -9.262469 },
          map: map,
          title: 'Coresense',
        });

        const infoWindow = new window.google.maps.InfoWindow({
          content: '<div style="color: black;"><medium style="font-size:20px">Coresense</medium> <br/> Rua de São Romão 69<br />2790-436 Queijas </div>',
        });

        marker.addListener('click', () => {
          infoWindow.open(map, marker);
        });

        // Optionally, open the InfoWindow by default
        infoWindow.open(map, marker);
      }
    };

    const scriptUrl = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBdrLtA1ADj6IH3-lnB0j7ydqVSwYnRmyc&callback=initMap';

    window.initMap = initMap;
    loadScript(scriptUrl, () => {
      if (window.google && window.google.maps) {
        initMap();
      }
    });
  }, []);

  return <div id="map" ></div>;
}

export default Maps;
